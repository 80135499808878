import { render, staticRenderFns } from "./Joinexcel.vue?vue&type=template&id=670e68f4&scoped=true&lang=pug&"
import script from "./Joinexcel.vue?vue&type=script&lang=ts&"
export * from "./Joinexcel.vue?vue&type=script&lang=ts&"
import style0 from "./Joinexcel.vue?vue&type=style&index=0&id=670e68f4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670e68f4",
  null
  
)

export default component.exports