import { render, staticRenderFns } from "./Foot.vue?vue&type=template&id=6ff453c1&scoped=true&lang=pug&"
import script from "./Foot.vue?vue&type=script&lang=ts&"
export * from "./Foot.vue?vue&type=script&lang=ts&"
import style0 from "./Foot.vue?vue&type=style&index=0&id=6ff453c1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff453c1",
  null
  
)

export default component.exports