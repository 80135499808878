import { render, staticRenderFns } from "./Joinmailexcel.vue?vue&type=template&id=0a4252dd&scoped=true&lang=pug&"
import script from "./Joinmailexcel.vue?vue&type=script&lang=ts&"
export * from "./Joinmailexcel.vue?vue&type=script&lang=ts&"
import style0 from "./Joinmailexcel.vue?vue&type=style&index=0&id=0a4252dd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a4252dd",
  null
  
)

export default component.exports